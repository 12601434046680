import React from "react";

export const EyesOpen = (props) => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6495 6.875C12.6495 7.7038 12.3322 8.49866 11.7674 9.08471C11.2026 9.67076 10.4365 10 9.63776 10C8.83899 10 8.07293 9.67076 7.50811 9.08471C6.94329 8.49866 6.62598 7.7038 6.62598 6.875C6.62598 6.0462 6.94329 5.25134 7.50811 4.66529C8.07293 4.07924 8.83899 3.75 9.63776 3.75C10.4365 3.75 11.2026 4.07924 11.7674 4.66529C12.3322 5.25134 12.6495 6.0462 12.6495 6.875Z"
        fill="#4F4F4F"
      />
      <path
        d="M0 6.875C0 6.875 3.61414 0 9.63771 0C15.6613 0 19.2754 6.875 19.2754 6.875C19.2754 6.875 15.6613 13.75 9.63771 13.75C3.61414 13.75 0 6.875 0 6.875ZM9.63771 11.25C10.756 11.25 11.8285 10.7891 12.6192 9.96859C13.41 9.14812 13.8542 8.03532 13.8542 6.875C13.8542 5.71468 13.41 4.60188 12.6192 3.78141C11.8285 2.96094 10.756 2.5 9.63771 2.5C8.51943 2.5 7.44694 2.96094 6.6562 3.78141C5.86545 4.60188 5.42121 5.71468 5.42121 6.875C5.42121 8.03532 5.86545 9.14812 6.6562 9.96859C7.44694 10.7891 8.51943 11.25 9.63771 11.25Z"
        fill="#4F4F4F"
      />
    </svg>
  );
};

export const EyesClosed = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6  text-gray-500 cursor-pointer"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
      />
    </svg>
  );
};

export const EyeIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ic:round-remove-red-eye">
        <path
          id="Vector"
          d="M9 3.375C5.25 3.375 2.0475 5.7075 0.75 9C2.0475 12.2925 5.25 14.625 9 14.625C12.75 14.625 15.9525 12.2925 17.25 9C15.9525 5.7075 12.75 3.375 9 3.375ZM9 12.75C6.93 12.75 5.25 11.07 5.25 9C5.25 6.93 6.93 5.25 9 5.25C11.07 5.25 12.75 6.93 12.75 9C12.75 11.07 11.07 12.75 9 12.75ZM9 6.75C7.755 6.75 6.75 7.755 6.75 9C6.75 10.245 7.755 11.25 9 11.25C10.245 11.25 11.25 10.245 11.25 9C11.25 7.755 10.245 6.75 9 6.75Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
