import React from "react";

const SettingsIcon = ({ fill }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1405 12.9384C19.1805 12.6384 19.2005 12.3284 19.2005 11.9984C19.2005 11.6784 19.1805 11.3584 19.1305 11.0584L21.1605 9.47844C21.2479 9.40634 21.3077 9.30614 21.3296 9.19491C21.3515 9.08368 21.3341 8.9683 21.2805 8.86844L19.3605 5.54844C19.3039 5.448 19.2137 5.37082 19.1057 5.33055C18.9977 5.29028 18.8789 5.28954 18.7705 5.32844L16.3805 6.28844C15.8805 5.90844 15.3505 5.58844 14.7605 5.34844L14.4005 2.80844C14.3827 2.69397 14.3246 2.58965 14.2365 2.51442C14.1484 2.43919 14.0363 2.39804 13.9205 2.39844H10.0805C9.84045 2.39844 9.65045 2.56844 9.61045 2.80844L9.25045 5.34844C8.66045 5.58844 8.12045 5.91844 7.63045 6.28844L5.24045 5.32844C5.02045 5.24844 4.77045 5.32844 4.65045 5.54844L2.74045 8.86844C2.62045 9.07844 2.66045 9.33844 2.86045 9.47844L4.89045 11.0584C4.84045 11.3584 4.80045 11.6884 4.80045 11.9984C4.80045 12.3084 4.82045 12.6384 4.87045 12.9384L2.84045 14.5184C2.75298 14.5905 2.69321 14.6907 2.67132 14.802C2.64944 14.9132 2.66681 15.0286 2.72045 15.1284L4.64045 18.4484C4.76045 18.6684 5.01045 18.7384 5.23045 18.6684L7.62045 17.7084C8.12045 18.0884 8.65045 18.4084 9.24045 18.6484L9.60045 21.1884C9.65045 21.4284 9.84045 21.5984 10.0805 21.5984H13.9205C14.1605 21.5984 14.3605 21.4284 14.3905 21.1884L14.7505 18.6484C15.3405 18.4084 15.8805 18.0884 16.3705 17.7084L18.7605 18.6684C18.9805 18.7484 19.2305 18.6684 19.3505 18.4484L21.2705 15.1284C21.3905 14.9084 21.3405 14.6584 21.1505 14.5184L19.1405 12.9384ZM12.0005 15.5984C10.0205 15.5984 8.40045 13.9784 8.40045 11.9984C8.40045 10.0184 10.0205 8.39844 12.0005 8.39844C13.9805 8.39844 15.6005 10.0184 15.6005 11.9984C15.6005 13.9784 13.9805 15.5984 12.0005 15.5984Z"
        fill={fill || "#100E86"}
      />
    </svg>
  );
};

export default SettingsIcon;
