import React from "react";
import { useNavigate } from "react-router-dom";
import {
  communityPlatforms,
  communityStatuses,
  platformsImg,
} from "../../utils/constants";
import Loader from "../loader";
import { useLocation } from "react-router-dom";
import CommunitiesDetails from "../../pages/communities/communities-details";

const CommunitiesGroupSelect = ({ community }) => {
  const { creator, platform, name, id, status } = community;
  const navigate = useNavigate();

  const handleDetailsNavigation = () => {
    navigate(`/communities/${id}`);
  };
  return (
    <div onClick={handleDetailsNavigation} className="pl-3 pb-4 cursor-pointer">
      <h5 className=" text-base xl:text-lg font-semibold truncate">{name}</h5>
      <div className="flex gap-2 truncate  mt-1 items-center">
        <img
          src={creator.image_url}
          className="w-6 h-6 rounded-full object-cover"
          alt="Illustrator for The Teacher teaching"
        />
        <span className="text-xs font-sm w-[46%] truncate">
          {creator.first_name} {creator.last_name}
        </span>
        <span className="text-xs font-sm flex items-center gap-1">
          {platformsImg[platform]}
          {communityPlatforms[platform]}
        </span>
        <span className="text-xs truncate font-sm">
          {communityStatuses[status]}
        </span>
      </div>
    </div>
  );
};

export default CommunitiesGroupSelect;

export const FilteredCommunityItem = ({ community, isLoading }) => {
  const { pathname } = useLocation();
  return (
    <div className="lg:grid lg:grid-cols-12 gap-3 xl:gap-3">
      <aside
        className={` md:col-span-4 h-[85vh] overflow-y-scroll tech-scroll ${
          pathname !== "/communities" ? "hidden lg:block" : "block"
        }`}
      >
        {isLoading ? (
          <Loader />
        ) : (
          community?.map((community) => (
            <div
              key={community.id}
              className="mt-2 py-2 border-gray-200 border-b  last:border-0"
            >
              <CommunitiesGroupSelect community={community} />
            </div>
          ))
        )}
      </aside>
      {pathname === "/communities" ? (
        <div className=" w-full h-full col-span-8  items-center justify-center hidden lg:flex">
          <p className=" font-bold">Click on a Community to view details</p>
        </div>
      ) : (
        <CommunitiesDetails />
      )}
    </div>
  );
};
