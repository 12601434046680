import React from "react";

const LiveClassIcon = ({ size }) => {
  const defaultSize = size ? size : "34";
  return (
    <svg
      width={defaultSize}
      height={defaultSize}
      viewBox={`0 0 34 34`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34" height="34" rx="4" fill="#100E86" fillOpacity="0.1" />
      <path
        d="M19 11.5C19.1658 11.5 19.3247 11.5658 19.4419 11.6831C19.5592 11.8003 19.625 11.9592 19.625 12.125V14.75L22.8831 12.4688C22.93 12.4359 22.9849 12.4166 23.042 12.4128C23.0991 12.4091 23.1561 12.4211 23.2069 12.4475C23.2576 12.4739 23.3001 12.5138 23.3298 12.5627C23.3594 12.6117 23.3751 12.6678 23.375 12.725V20.275C23.3751 20.3322 23.3594 20.3883 23.3298 20.4373C23.3001 20.4862 23.2576 20.5261 23.2069 20.5525C23.1561 20.5789 23.0991 20.5909 23.042 20.5872C22.9849 20.5834 22.93 20.5641 22.8831 20.5313L19.625 18.25V20.875C19.625 21.0408 19.5592 21.1997 19.4419 21.3169C19.3247 21.4342 19.1658 21.5 19 21.5H10.25C10.0842 21.5 9.92527 21.4342 9.80806 21.3169C9.69085 21.1997 9.625 21.0408 9.625 20.875V12.125C9.625 11.9592 9.69085 11.8003 9.80806 11.6831C9.92527 11.5658 10.0842 11.5 10.25 11.5H19ZM13.625 14.5181C13.5674 14.5181 13.5115 14.538 13.4669 14.5745C13.4222 14.6109 13.3915 14.6617 13.38 14.7181L13.375 14.7675V18.2313C13.375 18.2717 13.3848 18.3116 13.4036 18.3475C13.4225 18.3833 13.4497 18.4141 13.483 18.437C13.5163 18.46 13.5547 18.4746 13.5949 18.4795C13.6351 18.4843 13.6759 18.4794 13.7137 18.465L13.7594 18.4425L16.4813 16.71C16.513 16.6897 16.5398 16.6624 16.5595 16.6303C16.5792 16.5982 16.5915 16.562 16.5953 16.5245C16.599 16.487 16.5943 16.4491 16.5814 16.4137C16.5684 16.3783 16.5477 16.3463 16.5206 16.32L16.4813 16.2887L13.7594 14.5563C13.719 14.5313 13.6724 14.5182 13.625 14.5187V14.5181Z"
        fill="#100E86"
      />
    </svg>
  );
};

export default LiveClassIcon;
