import React from "react";

const AssignmentIcon = ({ size }) => {
  const defaultSize = size ? size : "34";
  return (
    <svg
      width={defaultSize}
      height={defaultSize}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="52" height="52" rx="4" fill="#100E86" fillOpacity="0.1" />
      <path
        d="M18 36H34"
        stroke="#100E86"
        strokeWidth="1.75"
        strokeLinecap="round"
      />
      <path
        d="M32.3367 22.1113L33.0787 21.3713C33.3709 21.0791 33.6027 20.7323 33.7608 20.3506C33.919 19.9689 34.0004 19.5598 34.0005 19.1466C34.0005 18.7335 33.9192 18.3243 33.7611 17.9426C33.603 17.5608 33.3713 17.214 33.0792 16.9218C32.7871 16.6296 32.4403 16.3978 32.0585 16.2396C31.6768 16.0815 31.2677 16 30.8545 16C30.4414 16 30.0322 16.0813 29.6505 16.2394C29.2688 16.3974 28.9219 16.6291 28.6297 16.9213L27.8877 17.6633C27.8877 17.6633 27.9807 19.2393 29.3707 20.6293C30.7607 22.0193 32.3367 22.1123 32.3367 22.1123L25.5197 28.9293C25.0577 29.3913 24.8277 29.6213 24.5727 29.8203C24.2727 30.0543 23.9477 30.2553 23.6037 30.4193C23.3127 30.5583 23.0027 30.6613 22.3837 30.8673L19.7577 31.7433M27.8877 17.6623L21.0707 24.4803C20.6087 24.9423 20.3777 25.1723 20.1797 25.4273C19.9454 25.7275 19.7446 26.0525 19.5807 26.3963C19.4417 26.6873 19.3387 26.9973 19.1317 27.6163L18.2567 30.2423M18.2567 30.2423L18.0437 30.8833C17.9938 31.0327 17.9865 31.1931 18.0226 31.3465C18.0588 31.4999 18.1369 31.6402 18.2484 31.7516C18.3598 31.863 18.5001 31.9412 18.6534 31.9773C18.8068 32.0135 18.9672 32.0062 19.1167 31.9563L19.7577 31.7433M18.2567 30.2423L19.7577 31.7433"
        stroke="#100E86"
        strokeWidth="1.75"
      />
    </svg>
  );
};

export default AssignmentIcon;
