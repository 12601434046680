import React from "react";
import GoogleIcon from "../../assets/icons/google";

export const GoogleIconButton = () => {
  return (
    <button className="border border-tech-gray1 py-3 w-full flex items-center justify-center text-xs font-bold text-[#263238] gap-5">
      <GoogleIcon /> Continue with Google
    </button>
  );
};

export const AuthButton = (props) => {
  return (
    <button
      className="rounded-full w-full flex justify-center py-4 px-4  text-lg text-white gradient font-bold tracking-wider mon-hover"
      {...props}
    >
      {props.children}
    </button>
  );
};

export const ButtonFillRounded = (props) => {
  return (
    <button
      className={`ease-in duration-100 w-full flex justify-center bg-[#100E86] hover:bg-[#100e86eb] text-lg  py-2  tracking-normal  rounded-[27px] text-white disabled:opacity-10 disabled:cursor-default font-semibold `}
      {...props}
    >
      {props.children}
    </button>
  );
};
export const ButtonFillRounded2 = (props) => {
  return (
    <button
      className="mon-hover bg-[#100E86] rounded-full lg:text-lg font-bold text-white py-4 px-16 lg:px-52 disabled:bg-slate-300"
      {...props}
    >
      {props.children}
    </button>
  );
};

export const ButtonOutline = (props) => {
  return (
    <button
      className={`ease-in duration-100 w-full flex justify-center border border-[#100E86] hover:bg-[#100e86eb] text-lg  py-2  tracking-normal  rounded-[27px] text-[#100E86] disabled:opacity-10 disabled:cursor-default font-semibold `}
      {...props}
    >
      {props.children}
    </button>
  );
};
