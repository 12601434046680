import React from "react";

const MenuIcon = ({ props }) => {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.166504 0.25H16.8332V2.33333H0.166504V0.25ZM0.166504 5.45833H16.8332V7.54167H0.166504V5.45833ZM0.166504 10.6667H16.8332V12.75H0.166504V10.6667Z"
        fill="#100E86"
      />
    </svg>
  );
};

export default MenuIcon;
